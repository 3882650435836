.card.member-card {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  max-width: 300px;
  text-align: center;
}

.card.member-card.member-card--open {
  flex-direction: column;
  animation: 3s linear member-card;
}

@keyframes member-card {
  0% {
    flex-direction: row;
  }

  19% {
    flex-direction: row;
  }

  20% {
    flex-direction: column;
  }
}

@media (min-width: 475px) {
  .card.member-card {
    margin: 0;
    width: 300px;
  }
}

@media (min-width: 1200px) {
  .card.member-card {
    flex-direction: column;
  }
  .card.member-card.member-card--open {
    animation: none;
  }
}

.member-card__picture {
  height: 5rem;
  width: 5rem;
  margin: 0 10px 0 0;
}

.member-card--open .member-card__picture {
  height: auto;
  width: 100%;
  margin: 0 0 10px 0;
  animation: 3s linear member-card-picture;
}

@keyframes member-card-picture {
  0% {
    height: 5rem;
    width: 5rem;
    opacity: 1;
    margin: 0 10px 0 0;
  }

  5% {
    height: 5rem;
    width: 5rem;
    opacity: 0;
    margin: 0 10px 0 0;
  }

  10% {
    height: 0;
    width: 0;
    margin: 0;
    opacity: 0;
  }

  20% {
    width: 0;
  }

  21% {
    height: 0;
    width: 100%;
    margin: 0;
  }

  30% {
    height: auto;
    width: 100%;
    margin: 0 0 10px 0;
    opacity: 1;
  }
}

@media (min-width: 1200px) {
  .member-card__picture {
    height: auto;
    width: 100%;
    margin: 0 0 10px 0;
  }
  .member-card--open .member-card__picture {
    animation: none;
  }
}

.member-card__text {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 5rem;
  justify-content: center;
}

.card__title.member-card__name {
  font-size: 1.5rem;
  line-height: 1.5rem;
  margin: 0;
}

.member-card--open .card__title.member-card__name {
  font-size: 2rem;
  line-height: 2rem;
  animation: 3s linear member-card-name;
}

@keyframes member-card-name {
  0% {
    font-size: 1.5rem;
    line-height: 1.5rem;
  }

  8% {
    font-size: 1.5rem;
    line-height: 1.5rem;
  }

  15% {
    font-size: 2rem;
    line-height: 2rem;
  }
}

@media (min-width: 1200px) {
  .card__title.member-card__name {
    font-size: 2rem;
    line-height: 2rem;
  }
  .member-card--open .card__title.member-card__name {
    animation: none;
  }
}

.member-card__nickname {
  display: none;
  font-family: "Indie Flower";
  font-style: italic;
  opacity: 0;
}

.member-card--open .member-card__nickname {
  display: block;
  font-size: 1.8rem;
  line-height: 2rem;
  margin-top: 0.5rem;
  opacity: 1;
  animation: 3s linear member-card-nickname;
}

@keyframes member-card-nickname {
  0% {
    display: none;
    line-height: 0;
    margin-top: 0;
    opacity: 0;
  }

  13% {
    display: block;
    line-height: 0;
    margin-top: 0;
    opacity: 0;
  }

  20% {
    line-height: 2rem;
    margin-top: 0.5rem;
    opacity: 0;
  }

  25% {
    opacity: 1;
  }
}


@media (min-width: 1200px) {
  .member-card__nickname {
    display: block;
    font-size: 1.8rem;
    line-height: 2rem;
    margin-top: 0.5rem;
    opacity: 1;
  }
  .member-card--open .member-card__nickname {
    animation: none;
  }
}

.member-card__dates {
  font-size: 1rem;
  line-height: 1.5rem;
}

.member-card--open .member-card__dates {
  font-size: 1.25rem;
  animation: 3s linear member-card-dates;
}

@keyframes member-card-dates {
  0% {
    font-size: 1rem;
  }

  8% {
    font-size: 1rem;
  }

  15% {
    font-size: 1.25rem;
  }
}

@media (min-width: 1200px) {
  .member-card__dates {
    font-size: 1.25rem;
  }
  .member-card--open .member-card__dates {
    animation: none;
  }
}
