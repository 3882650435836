.starry-night {
  background: #000014;
  display: inline-block;
  line-height: 0;
}

.starry-night__canvas {
  opacity: 0;
  transition: opacity 600ms;
}

.starry-night__canvas.starry-night--visible {
  opacity: 1;
}

.starry-night--full-width {
  width: 100%;
}
.starry-night--full-height {
  height: 100%;
}
