.card {
  background: white;
  border-radius: 2px;
  box-shadow: 0 0 4px 2px rgba(22, 22, 22, .4);
  color: black;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
}

.card__title {
  font-family: Indie Flower;
  font-size: 1.5rem;
  line-height: 1.5rem;
  margin: 1rem 0 0;
  text-align: center;
}

@media (min-width: 375px) {
  .card__title {
    font-size: 2rem;
    line-height: 2rem;
  }
}
