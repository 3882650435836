.thomas-pesquet-container {
  position: absolute;
  inset: 0;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.thomas-pesquet {
  transform: translate(0, -67vh);
  animation: 90s linear thomas-pesquet-position infinite;
}

.thomas-pesquet::before {
  background-image: url("./thomas-pesquet-1.png");
  background-size: contain;
  background-repeat: no-repeat;
  content: "";
  display: inline-block;
  height: 50px;
  width: 50px;
  animation: 2s steps(1, end) thomas-pesquet-sprite infinite;
}

@keyframes thomas-pesquet-position {
  0% {
    transform: translate(0, -67vh) rotate(0deg);
  }

  25% {
    transform: translate(-67vw, 0) rotate(900deg);
  }

  50% {
    transform: translate(0, 67vh) rotate(1800deg);
  }

  75% {
    transform: translate(67vw, 0) rotate(2700deg);
  }

  100% {
    transform: translate(0, -67vh) rotate(0deg);
  }
}

@keyframes thomas-pesquet-sprite {
  0% {
    background-image: url("./thomas-pesquet-1.png");
  }

  50% {
    background-image: url("./thomas-pesquet-2.png");
  }
}
