@media (min-width: 475px) {
  .title-card {
    max-width: 300px;
  }
}

.title-card__constellation {
  background: black;
  display: none;
  width: 300px;
  height: 300px;
}

@media (min-width: 1200px) {
  .title-card__constellation {
    display: block;
  }
}

.title-card__description {
  font-size: 1rem;
  text-align: center;
}

@media (min-width: 1200px) {
  .title-card__description {
    font-size: inherit;
  }
}
